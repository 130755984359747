<template>
  <el-card shadow="never" v-loading="loading">
    <div slot="header">
      <span class="iconfont icon-Staff">&nbsp;操作员管理</span>
    </div>
    <div class="card_header_flex">
      <div>
        <el-button type="primary" size="small" @click="dialogAddUser=true">新增用户</el-button>
      </div>
      <div>
        <el-input
          v-model="search"
          placeholder="请输入用户编号、名称、手机号"
          suffix-icon="el-icon-search"
          style="width:300px"
          size="small"
          @change="initLoad"
        ></el-input>
      </div>
    </div>
    <el-table
      :data="userList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
      size="mini"
    >
      <el-table-column prop="nickName" label="名称">
        <template slot-scope="scope">
          <div>
            {{scope.row.mobile}}
            <i
              :class="scope.row.mobile==userInfo.mobile?'el-icon-user-solid':''"
              style="margin-left:10px;color:#5e72f9"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="电话"></el-table-column>
      <el-table-column prop="userLevel" label="角色">
        <template slot-scope="scope">
          <el-tag size="mini" effect="dark" v-if="scope.row.userLevel=='super_admin'">超级管理员</el-tag>
          <el-tag size="mini" v-else>管理员</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status=='disable'" style="color:#F56C6C">禁用中</span>
          <span v-else>已启用</span>
        </template>
      </el-table-column>
      <el-table-column prop="regAuthority" label="超级注册权限">
        <template slot="header">
          超级注册权限
          <el-tooltip class="item" content="全部场所微信注册终端权限" placement="top">
            <i class="el-icon-warning-outline" style=" cursor: pointer;font-size:14px"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.regAuthority==0" style="color:#F56C6C">关闭</span>
          <span v-else>开启</span>
        </template>
      </el-table-column>
      <el-table-column prop="lastLogin" label="最后登录时间"></el-table-column>
      <el-table-column label="当前支付套餐">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.packageId"
            size="mini"
            placeholder="未选择套餐"
            @change="onChangePackage(scope.row)"
            :disabled="scope.row.userLevel =='super_admin'"
          >
            <el-option
              v-for="item in packageList"
              :key="item.id"
              :label="item.packageName"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="openEdit(scope.row)">编辑</el-button>
          <el-button
            size="mini"
            type="text"
            v-if="scope.row.status=='disable' && scope.row.userLevel !='super_admin'"
            @click="handleEditByStatus('enable', scope.row)"
          >启用</el-button>
          <el-button
            size="mini"
            type="text"
            v-if="scope.row.status=='enable' && scope.row.userLevel !='super_admin'"
            @click="handleEditByStatus('disable', scope.row)"
          >禁用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_right">
      <el-pagination
        background
        layout="total,prev, pager, next ,jumper"
        :total="userList.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- 添加用户 -->
    <el-dialog
      :visible.sync="dialogAddUser"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddUser"
    >
      <span slot="title">添加用户</span>
      <el-form
        :model="form"
        ref="form"
        label-width="140px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="名称"
          prop="nickName"
          :rules="{ required: true, message: '请输入用户的名称', trigger: 'blur' }"
        >
          <el-input v-model="form.nickName" placeholder="请输入用户的名称"></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="mobile"
          :rules="[{ required: true, message: '请输入用户的手机号', trigger: 'blur' },{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
        >
          <el-input v-model="form.mobile" maxlength="11" show-word-limit placeholder="请输入用户的手机号"></el-input>
        </el-form-item>

        <el-form-item
          label="初始密码"
          prop="password"
          :rules="{ required: true, message: '请输入用户的登录密码', trigger: 'blur' }"
        >
          <el-input v-model="form.password" placeholder="请输入用户的初始登录密码"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="form.userLevel" placeholder="请选择角色" style="width:100%">
            <el-option
              v-for="item in optionsLevel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="超级注册权限">
          <template slot="label">
            <span>超级注册权限</span>
            <el-tooltip class="item" content="全部场所微信注册终端权限" placement="top">
              <i class="el-icon-warning-outline" style=" cursor: pointer;font-size:16px"></i>
            </el-tooltip>
          </template>
          <el-radio-group v-model="form.regAuthority" size="mini">
            <el-radio-button label="1">开启</el-radio-button>
            <el-radio-button label="0">关闭</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="form.remark" :rows="6"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddUser=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogEditUser"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogEditUser"
    >
      <span slot="title">编辑用户</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="140px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="名称"
          prop="nickName"
          :rules="{ required: true, message: '请输入用户的名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.nickName" placeholder="请输入用户的名称"></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="mobile"
          :rules="[{ required: true, message: '请输入用户的手机号', trigger: 'blur' },{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
        >
          <el-input v-model="editForm.mobile" placeholder="请输入用户的手机号"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="editForm.userLevel" placeholder="请选择角色" style="width:100%">
            <el-option
              v-for="item in optionsLevel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="editForm.status" size="mini">
            <el-radio-button label="enable">启用</el-radio-button>
            <el-radio-button label="disable">禁用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="超级注册权限">
          <template slot="label">
            <span>超级注册权限</span>
            <el-tooltip class="item" content="全部场所微信注册终端权限" placement="top">
              <i class="el-icon-warning-outline" style=" cursor: pointer;font-size:16px"></i>
            </el-tooltip>
          </template>
          <el-radio-group v-model="editForm.regAuthority" size="mini">
            <el-radio-button label="1">开启</el-radio-button>
            <el-radio-button label="0">关闭</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="editForm.remark" :rows="6"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditUser=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditUser">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import {
  getSystemUserApi,
  editSystemUserApi,
  addSystemUserApi,
  getPackageGroupByIdApi
} from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      loading:true,
      userList: [],
      search: "", //查询条件 可查 userId nickName mobile
      dialogEditUser: false,
      dialogAddUser: false,
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      form: {
        mobile: "",
        nickName: "",
        regAuthority: "0",
        userLevel: "admin",
        password: "",
        remark: ""
      },
      optionsLevel: [
        { value: "super_admin", label: "超级管理员" },
        { value: "admin", label: "管理员" }
      ],
      editForm: {
        headPortrait: "",
        mobile: "",
        nickName: "",
        regAuthority: "",
        password: "",
        status: "",
        userId: "",
        userLevel: "",
        remark: ""
      },
      packageList: []
    };
  },
  computed: {
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    }
  },
  created() {
    this.initLoad();
    this.initLoadPackage();
  },
  methods: {
    //初始化
    initLoad() {
      getSystemUserApi({ param: this.search }).then(res => {
        this.userList = res.data;
        this.loading=false;
      });
    },
    //查询可以勾选的套餐
    initLoadPackage() {
      getPackageGroupByIdApi().then(res => {
        if (res) this.packageList = res.data;
      });
    },
    //启用/禁用 用户
    handleEditByStatus(status, obj) {
      editSystemUserApi({ userId: obj.userId, status: status }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //选择套餐事件
    onChangePackage(row) {
      editSystemUserApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //添加用户
    onSubmitAddUser() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          addSystemUserApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogAddUser = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加用户窗口关闭事件
    closeDialogAddUser() {
      this.$refs["form"].resetFields();
    },
    //修改用户
    onSubmitEditUser() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          editSystemUserApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogEditUser = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加用户窗口关闭事件
    closeDialogEditUser() {
      this.$refs["editForm"].resetFields();
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    //打开编辑窗口
    openEdit(obj) {
      this.dialogEditUser = true;
      this.editForm = { ...obj };
    }
  }
};
</script>
<style lang='scss'>
</style>